<template>
  <div>
    <div class="relative" style="max-height: 512px">
      <div ref="canvas" />
      <div
        v-if="!assetsLoaded"
        class="
          absolute
          top-0
          left-0
          w-full
          bg-yellow-500
          flex flex-col
          items-center
          justify-center
          h-full
          text-white text-2xl
        "
      >
        <div>Loading assets</div>
        <img src="@/assets/rings.svg" class="h-16 w-16" />
      </div>
    </div>
    <div v-if="loopUrl">
      <audio ref="audioPlayer" autoplay controls loop class="hidden">
        <source :src="loopUrl" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <div class="flex justify-center mt-1">
        <button @click="playAudio">Unmute</button>
        <span> / </span>
        <button @click="pauseAudio">Mute</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watch, ref, onMounted } from 'vue'

const backgroundList = {
  'Rainbow Ruins': 'bg13',
  'Aqua Sky Ruins': 'ruins1_fasterer',
  'Alien Texture': 'bg38',
  'Fire Sky': 'bg43',
  'Minimal Blue Sky': 'bg3',
  Coral: 'bg39',
  'Dusk City': 'bg27',
  'Cyan and Magenta': 'bg37',
  Bricks: 'bg29',
  'Japanese Signs': 'special_bg2',
  'Light Blue Sky': 'bg25',
  Water: 'bg35',
  'Abstract Sunset': 'bg14',
  'Purple Mountains': 'bg9_purpmountains',
  'Purple Sky': 'bg1',
  'Purple Night': 'bg36',
  'Mauve Texture': 'bg10',
  'Stars 2': 'bgstars2',
  'Salmon Texture': 'bg31',
  'Tape Water': 'bgtapewater',
  'Tape Bricks': 'bgbricks',
  'Purple Gradient': 'bg47',
  'Aqua City': 'bggreencity',
  'Aqua Haze': 'bggreener',
  'Pink and Yellow': 'bgpinkyellow',
  Stone: 'bg12',
  Peach: 'bg42',
  'Mauve Sky': 'bg4',
  'Deep Blue Sky': 'bg26',
  'Rare Wavy': 'bgwavy',
  "Rare '80s Grid": 'bggrid',
  'Rare Starfield': 'bgstars',
  'Rare Road': 'driv0r',
  'Rare Aqua Gradient': 'bg34',
  Stars: 'starsbg_2',
  'Rainy Day': 'bgrainyday',
  'Rare Flash': 'hunka',
  'Rare Green Sky': 'bgraregreensky',
  'Rare Pink Texture': 'bgrarepink',
  'Rare Blue Texture': 'bg40',
  'Rare Green Mountains': 'bg9',
  'Rare Pink Sky': 'bg24',
  'Rare Trippy': 'trippie',
  'Rare Blue': 'bg52',
  'Rare Lights': 'bglights',
  'Rare Purple Gradient': 'bgpurplerare',
  'Rare Yellow': 'bgyellowgreen',
  '<none>': 'bgdevtokens',
}

export default defineComponent({
  components: {},
  props: {
    blitmapId: {
      type: Number,
      required: true,
    },
    dreamloopId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const dreamloopsBaseUrl = 'https://dlv1api.bitlectrolabs.com/dreamloopsv1/metadata/'
    const canvas = ref(null)
    const assetsLoaded = ref(false)
    const loopUrl = ref(null)
    const audioPlayer = ref(null)
    let p5canvas // eslint-disable-line no-unused-vars
    let script

    function updateSketch() {
      document.getElementById('defaultCanvas0').outerHTML = ''
      assetsLoaded.value = false
      p5canvas = new window.p5(script)
    }

    function playAudio() {
      audioPlayer.value.play()
    }

    function pauseAudio() {
      audioPlayer.value.pause()
    }

    watch(
      () => props.blitmapId,
      () => {
        // console.log('blitmapId changed')
        updateSketch()
      },
    )

    watch(
      () => props.dreamloopId,
      () => {
        // console.log('dreamloopId changed')
        updateSketch()
      },
    )

    watch(
      () => loopUrl.value,
      (newVal, oldVal) => {
        if (oldVal != null && audioPlayer.value) {
          // console.log('loop Url changed')
          audioPlayer.value.load()
        }
      },
    )

    script = (p5) => {
      let pagePadding = 16
      let graphics
      let blitmap
      let gif
      let angle = 0.6
      let box_width = 210
      let box_step = 8
      let boxes_num = 4
      let fps = 30
      let rotationStep = 0.025
      let isOriginal = false
      let wrapped = false

      p5.preload = () => {
        fetch(`${dreamloopsBaseUrl}${props.dreamloopId}`)
          .then((response) => response.json())
          .then(function (data) {
            let dreamloopData = data

            let wrapped = dreamloopData.attributes.find(
              (element) => element.trait_type === 'element',
            )

            if (wrapped.value == 'wrapped') {
              wrapped = true
              alert('Error: Dreamloop is still wrapped. Please choose another one.')
            } else {
              let bgTrait = dreamloopData.attributes.find(
                (element) => element.trait_type === 'background',
              )
              let bgName = bgTrait.value
              if (bgName == '') {
                bgName = '<none>'
              }

              loopUrl.value = dreamloopData.animation_url

              p5.loadImage(`blitmaps/${props.blitmapId}.png`, (img) => {
                blitmap = img
                p5.loadImage(`bgs/${backgroundList[bgName]}.gif`, (img) => {
                  gif = img
                  assetsLoaded.value = true
                })
              })

              if (props.blitmapId < 100) {
                isOriginal = true
              }
            }
          })
      }

      p5.setup = () => {
        p5.frameRate(fps)
        graphics = p5.createGraphics(512, 512, p5.WEBGL)
        if (p5.windowWidth - pagePadding * 2 < 512) {
          p5.createCanvas(
            p5.windowWidth - pagePadding * 2,
            p5.windowWidth - pagePadding * 2,
          ).parent(canvas.value)
        } else {
          p5.createCanvas(512, 512).parent(canvas.value)
        }
      }

      p5.draw = () => {
        if (!assetsLoaded.value || wrapped) {
          p5.background(90, 90, 90)
        } else {
          graphics.clear()
          graphics.reset()
          graphics.ambientLight(70, 70, 70)
          graphics.pointLight(250, 250, 250, 0, 0, 300)

          graphics.push()
          graphics.translate(0, 0, 150)
          graphics.rotateY(angle)
          graphics.noStroke()

          if (isOriginal) {
            graphics.fill(255, 215, 0)
          } else {
            graphics.fill(70)
          }

          graphics.box(box_width, box_width, box_step)

          for (let i = 1; i <= boxes_num / 2; i++) {
            graphics.push()
            graphics.translate(0, 0, i * box_step)
            graphics.box(box_width - i * box_step, box_width - i * box_step, box_step)

            if (i === boxes_num / 2) {
              graphics.push()
              graphics.translate(0, 0, -boxes_num * box_step - box_step / 2 - 1)
              graphics.rectMode(p5.CENTER)
              graphics.angleMode(p5.DEGREES)
              graphics.rotateY(180)
              graphics.angleMode(p5.RADIANS)
              graphics.texture(blitmap)
              graphics.rect(
                0,
                0,
                box_width - i * box_step - box_step * 2,
                box_width - i * box_step - box_step * 2,
              )
              graphics.pop()
            }

            graphics.pop()
          }

          for (let i = 1; i <= boxes_num / 2; i++) {
            graphics.push()
            graphics.translate(0, 0, -i * box_step)
            graphics.box(box_width - i * box_step, box_width - i * box_step, box_step)

            if (i === boxes_num / 2) {
              graphics.push()
              graphics.translate(0, 0, boxes_num * box_step + box_step / 2 + 1)
              graphics.rectMode(p5.CENTER)
              graphics.texture(blitmap)
              graphics.rect(
                0,
                0,
                box_width - i * box_step - box_step * 2,
                box_width - i * box_step - box_step * 2,
              )
              graphics.pop()
            }

            graphics.pop()
          }
          graphics.pop()

          p5.clear()
          p5.image(gif, 0, 0, p5.width, p5.height)
          p5.image(graphics, 0, 0, p5.width, p5.height)

          angle -= rotationStep
        }
      }

      p5.windowResized = () => {
        if (p5.windowWidth - pagePadding * 2 < 512) {
          p5.resizeCanvas(p5.windowWidth - pagePadding * 2, p5.windowWidth - pagePadding * 2)
        }
      }
    }

    onMounted(() => {
      p5canvas = new window.p5(script)
    })

    return {
      assetsLoaded,
      canvas,
      loopUrl,
      audioPlayer,
      playAudio,
      pauseAudio,
    }
  },
})
</script>
