<template>
  <div class="max-w-512 mt-4 pb-28">
    <div class="flex flex-col space-y-8">
      <div>
        <p class="text-lg leading-tight">
          <strong>Blitzzz</strong> are a fusion of
          <a
            class="text-blue-700 underline"
            target="_blank"
            href="https://opensea.io/collection/blitmap"
            >Blitmaps</a
          >
          and
          <a
            class="text-blue-700 underline"
            target="_blank"
            href="https://opensea.io/collection/dreamloops"
            >Dreamloops</a
          >. Choose an awesome theme music for your Blitmap and mint your own Blitz.
        </p>
        <ul class="mt-2 list-disc pl-5 leading-tight space-y-2">
          <li>The Blitmap and the Dreamloop must be in your wallet to mint.</li>
          <li>Every Blitmap and Dreamloop can only be used once.</li>
          <li>
            You don't have to burn or transfer Blitmap/Dreamloop.
            <strong>They won't be touched at all.</strong>
          </li>
          <li>
            The <strong>first 15 Blitzzz are free</strong> to mint, after that there's a
            {{ price }} ETH fee.
          </li>
        </ul>
      </div>

      <base-button
        v-if="!walletIsConnected"
        type="primary"
        size="xl"
        full-width
        @click="openConnectWalletModal"
        >Connect Wallet to Mint</base-button
      >
      <base-button v-else-if="isMinting" type="default" size="lg" full-width :loading="true"
        >Minting…</base-button
      >
      <a
        v-else-if="hasMinted"
        class="block w-full p-4 bg-blue-500 text-center text-white font-bold uppercase"
        href="https://opensea.io/collection/blitzzz"
        target="_blank"
        >Go to Opensea</a
      >
      <base-button v-else-if="!isOwnerOfInputs" type="default" size="lg" full-width
        >Please choose your NFTs below</base-button
      >
      <base-button v-else-if="alreadyUsed" type="default" size="lg" full-width
        >NFT was already used :(</base-button
      >
      <base-button
        v-else-if="totalSupply >= 15"
        type="primary"
        size="xl"
        full-width
        @click="mintBlitz"
        >Mint for {{ price }} ETH</base-button
      >
      <base-button v-else type="primary" size="xl" full-width @click="mintBlitz"
        >Mint for free ({{ 15 - totalSupply }} left)</base-button
      >

      <div>
        <blitzzz-sketch :blitmap-id="blitmapId" :dreamloop-id="dreamloopId" />
        <div v-if="!walletIsConnected" class="flex flex-col space-y-3 mt-2">
          <div>
            <label>Enter Blitmap ID (0 to 1699) to preview</label>
            <input
              v-model="blitmapIdInput"
              class="w-full"
              type="text"
              min="0"
              max="1699"
              inputmode="decimal"
              pattern="^[0-9]*[.]?[0-9]*$"
              minlength="1"
              maxlength="4"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              @keypress="validateNumber"
              @blur="updateBlitmap"
              @keyup.enter="updateBlitmap"
            />
          </div>

          <div>
            <label>Enter Dreamloop ID (1 to 9410) to preview</label>
            <input
              v-model="dreamloopIdInput"
              class="w-full"
              type="text"
              min="1"
              max="9410"
              inputmode="decimal"
              pattern="^[0-9]*[.]?[0-9]*$"
              minlength="1"
              maxlength="4"
              spellcheck="false"
              autocomplete="off"
              autocorrect="off"
              @keypress="validateNumber"
              @blur="updateDreamloop"
              @keyup.enter="updateDreamloop"
            />
          </div>
        </div>
        <div v-else-if="!isMinting">
          <div>
            <h2 class="text-lg font-bold">Choose Input Blitmap</h2>
            <div v-if="blitmapBalance > 0" class="flex space-x-2 mt-1 w-full overflow-x-auto">
              <div
                v-for="blitmap in $store.state.wallet.blitmaps"
                :key="blitmap"
                class="w-28 flex-shrink-0 cursor-pointer relative"
                @click.prevent="selectBlitmap(blitmap)"
              >
                <img :src="'https://api.blitmap.com/v1/png/' + blitmap" class="w-full" />
                <div
                  v-show="parseInt(blitmap) == blitmapIdInput"
                  class="
                    w-full
                    h-full
                    bg-black
                    absolute
                    top-0
                    left-0
                    bg-opacity-40
                    text-white
                    uppercase
                    font-bold
                    border-pink-500 border-8
                  "
                >
                  <div class="flex justify-center items-end w-full h-full">
                    <span class="mb-2">selected</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              You have no Blitmaps in your wallet. You can get one
              <a
                class="text-blue-700 underline"
                target="_blank"
                href="https://opensea.io/collection/blitmap"
                >here</a
              >
              and then come back to this site.
            </div>
          </div>

          <div class="mt-4">
            <h2 class="text-lg font-bold">Choose Input Dreamloop</h2>
            <div v-if="dreamloopBalance > 0" class="flex space-x-2 mt-1 w-full overflow-x-auto">
              <div
                v-for="dreamloop in $store.state.wallet.dreamloops"
                :key="dreamloop"
                class="w-28 flex-shrink-0 cursor-pointer relative"
                @click.prevent="selectDreamloop(dreamloop.id)"
              >
                <img :src="dreamloop.image" class="w-full" />
                <div
                  v-show="parseInt(dreamloop.id) == dreamloopIdInput"
                  class="
                    w-full
                    h-full
                    bg-black
                    absolute
                    top-0
                    left-0
                    bg-opacity-40
                    text-white
                    uppercase
                    font-bold
                    border-pink-500 border-8
                  "
                >
                  <div class="flex justify-center items-end w-full h-full">
                    <span class="mb-2">selected</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              You have no Dreamloops in your wallet. You can get one
              <a
                class="text-blue-700 underline"
                target="_blank"
                href="https://opensea.io/collection/dreamloops"
                >here</a
              >and then come back to this site.
            </div>
          </div>
        </div>
      </div>
      <base-button v-if="walletIsConnected" size="xl" full-width @click="disconnectWallet"
        >Disconnect wallet</base-button
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import BaseButton from '@/components/BaseButton'
import BlitzzzSketch from '@/components/BlitzzzSketch'

export default defineComponent({
  name: 'App',
  components: {
    BaseButton,
    BlitzzzSketch,
  },
  setup() {
    const store = useStore()
    const isOwnerOfInputs = ref(false)
    const alreadyUsed = ref(false)
    const initialBlitmapId = 63
    const blitmapId = ref(initialBlitmapId)
    const blitmapIdInput = ref(initialBlitmapId)

    const initialDreamloopId = 4554
    const dreamloopId = ref(initialDreamloopId)
    const dreamloopIdInput = ref(initialDreamloopId)

    const price = process.env.VUE_APP_PRICE

    const walletIsConnected = computed(() => store.state.wallet.isConnected)

    watch(
      () => blitmapId.value,
      () => {
        checkOwnership()
      },
    )

    watch(
      () => dreamloopId.value,
      () => {
        checkOwnership()
      },
    )

    async function checkOwnership() {
      if (walletIsConnected.value) {
        store.commit('wallet/setHasMinted', false)

        let isOwner = await store.dispatch('wallet/checkOwnership', {
          blitmapId: blitmapId.value,
          dreamloopId: dreamloopId.value,
        })
        let used = await store.dispatch('wallet/checkAlreadyUsed', {
          blitmapId: blitmapId.value,
          dreamloopId: dreamloopId.value,
        })
        alreadyUsed.value = used
        isOwnerOfInputs.value = isOwner
      }
    }

    function openConnectWalletModal() {
      if (!walletIsConnected.value) {
        store.commit('wallet/setConnectWalletModalIsOpen', true)
      }
    }

    async function disconnectWallet() {
      await store.dispatch('wallet/disconnect')
    }

    function validateNumber(event) {
      let charCode = event.keyCode

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault()
      }
    }

    function updateBlitmap() {
      if (blitmapIdInput.value !== blitmapId.value) {
        let lowerLimit = Math.max(parseInt(blitmapIdInput.value), 0)
        let upperLimit = Math.min(lowerLimit, 1699)
        blitmapIdInput.value = upperLimit
        blitmapId.value = upperLimit
      }
    }

    function updateDreamloop() {
      if (dreamloopIdInput.value !== dreamloopId.value) {
        let lowerLimit = Math.max(parseInt(dreamloopIdInput.value), 1)
        let upperLimit = Math.min(lowerLimit, 9410)
        dreamloopIdInput.value = upperLimit
        dreamloopId.value = upperLimit
      }
    }

    function selectBlitmap(blitmap) {
      blitmapIdInput.value = parseInt(blitmap)
      blitmapId.value = parseInt(blitmap)
    }

    function selectDreamloop(dreamloop) {
      dreamloopIdInput.value = parseInt(dreamloop)
      dreamloopId.value = parseInt(dreamloop)
    }

    function mintBlitz() {
      store.dispatch('wallet/forge', {
        blitmapId: blitmapId.value,
        dreamloopId: dreamloopId.value,
      })
    }

    return {
      blitmapBalance: computed(() => store.state.wallet.blitmapBalance),
      dreamloopBalance: computed(() => store.state.wallet.dreamloopBalance),
      dreamloops: computed(() => store.state.wallet.dreamloops),
      totalSupply: computed(() => store.state.wallet.totalSupply),
      isMinting: computed(() => store.state.wallet.isMinting),
      hasMinted: computed(() => store.state.wallet.hasMinted),
      walletIsConnected,
      validateNumber,
      blitmapId,
      dreamloopId,
      blitmapIdInput,
      dreamloopIdInput,
      updateBlitmap,
      updateDreamloop,
      openConnectWalletModal,
      selectBlitmap,
      selectDreamloop,
      disconnectWallet,
      mintBlitz,
      price,
      isOwnerOfInputs,
      alreadyUsed,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
