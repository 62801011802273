<template>
  <div class="bg-white flex">
    <div class="flex flex-1 flex-col overflow-hidden w-0">
      <div
        v-if="isWrongNetwork"
        class="
          bg-red-600
          fixed
          flex
          font-bold
          h-16
          items-center
          justify-center
          left-0
          px-4
          text-center text-sm text-white
          top-0
          w-full
          z-20
        "
      >
        You're connected to the wrong network. Please connect to {{ correctNetwork.name }}
      </div>

      <div
        :class="{ 'mt-16': isWrongNetwork }"
        class="w-full h-16 bg-pink-500 text-white flex items-center px-4 justify-between"
      >
        <div><h1 class="font-bold text-2xl">Blitzzz</h1></div>
        <div class="text-md">
          <a
            v-if="!walletIsConnected"
            class="hover:underline cursor-pointer"
            @click.prevent="openConnectWalletModal"
            >Connect Wallet</a
          >
          <a v-else class="hover:underline cursor-pointer" @click.prevent="disconnectWallet">{{
            shortenAddress(connectedAddress)
          }}</a>
        </div>
      </div>

      <main class="p-4 flex justify-center">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="$route.path" />
          </transition>
        </router-view>
      </main>
    </div>
  </div>
  <footer class="bg-pink-500 text-white px-4 py-6 flex justify-center text-center">
    <ul>
      <li>
        <a href="https://opensea.io/collection/blitzzz" target="_blank" class="hover:underline"
          >Opensea Collection</a
        >
      </li>
      <li>
        <a href="https://twitter.com/schmackofant" target="_blank" class="hover:underline"
          >Twitter</a
        >
      </li>
    </ul>
  </footer>
  <connect-wallet-modal />
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { shortenAddress } from '@/utils'
import BaseButton from '@/components/BaseButton'
import ConnectWalletModal from '@/components/ConnectWalletModal'

export default defineComponent({
  name: 'App',
  components: {
    BaseButton,
    ConnectWalletModal,
  },
  setup() {
    const store = useStore()

    const walletIsConnected = computed(() => store.state.wallet.isConnected)

    function openConnectWalletModal() {
      if (!walletIsConnected.value) {
        store.commit('wallet/setConnectWalletModalIsOpen', true)
      }
    }

    async function disconnectWallet() {
      await store.dispatch('wallet/disconnect')
    }

    return {
      isWrongNetwork: computed(() => store.getters['wallet/isWrongNetwork']),
      correctNetwork: computed(() => store.getters['wallet/correctNetwork']),
      connectedAddress: computed(() => store.state.wallet.connectionInfo.address),
      openConnectWalletModal,
      walletIsConnected,
      shortenAddress,
      disconnectWallet,
    }
  },
})
</script>
