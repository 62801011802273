import { ethers } from 'ethers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { useToast } from 'vue-toastification'
import localStorageUtils from '@/utils/localstorage.js'

import blitmapABI from '@/abis/Blitmap.json'
import blitzzzABI from '@/abis/Blitzzz.json'

// Initial state
const state = () => ({
  isConnected: localStorageUtils.read('walletIsConnected'),
  walletProvider: localStorageUtils.read('walletProvider') || null,
  connectionInfo: localStorageUtils.read('connectionInfo') || {
    address: null,
    chainId: null,
  },
  connectWalletModalIsOpen: false,
  loadingUserDetails: false,
  ethBalance: 0.0,
  ensName: null,
  currentBlock: 0,
  totalSupply: 0,
  blitmapBalance: 0,
  blitmaps: new Set(),
  dreamloopBalance: 0,
  dreamloops: [],
  isMinting: false,
  hasMinted: false,
})

const chainIds = [
  {
    id: 1,
    name: 'mainnet',
  },
  {
    id: 3,
    name: 'ropsten',
  },
  {
    id: 4,
    name: 'rinkeby',
  },
]

const toast = useToast()

let signer
let ethersInstance
let blitzzzContract
let blitzzzContractWithSigner
let blitmapContract
let dreamloopContract

function addressEqual(a, b) {
  return a.toLowerCase() === b.toLowerCase()
}

async function tokenList(tokenAddress, account) {
  const token = new ethers.Contract(tokenAddress, blitmapABI, ethersInstance)

  const sentLogs = await token.queryFilter(token.filters.Transfer(account, null))
  const receivedLogs = await token.queryFilter(token.filters.Transfer(null, account))

  const logs = sentLogs
    .concat(receivedLogs)
    .sort((a, b) => a.blockNumber - b.blockNumber || a.transactionIndex - b.TransactionIndex)

  const owned = new Set()

  for (const log of logs) {
    const { from, to, tokenId } = log.args

    if (addressEqual(to, account)) {
      owned.add(tokenId.toString())
    } else if (addressEqual(from, account)) {
      owned.delete(tokenId.toString())
    }
  }

  // console.log([...owned].join('\n'))
  return owned
}

// getters
const getters = {
  currentNetwork: (state) => {
    return chainIds.find((n) => n.id === state.connectionInfo.chainId)
  },
  correctNetwork: () => {
    return chainIds.find((n) => n.id === parseInt(process.env.VUE_APP_ETHEREUM_CHAIN_ID))
  },
  getNetworkById: () => (id) => {
    return chainIds.find((n) => n.id === id)
  },
  isWrongNetwork: (state, getters) => {
    return (
      state.isConnected &&
      parseInt(getters['currentNetwork'].id) !== parseInt(process.env.VUE_APP_ETHEREUM_CHAIN_ID)
    )
  },
  isTransacting: (state) => {
    return state.approvingVita || state.stakingVita || state.unstakingVita || state.isVoting
  },
}

// actions
const actions = {
  async initUser({ commit, state, dispatch }) {
    const network = await ethersInstance.getNetwork()
    commit('setChainId', network.chainId)

    // Get ENS Name if available
    const ensName = await ethersInstance.lookupAddress(state.connectionInfo.address)
    commit('setEnsName', ensName)

    blitzzzContract = new ethers.Contract(
      process.env.VUE_APP_BLITZZZ_ADDRESS,
      blitzzzABI,
      ethersInstance,
    )
    blitzzzContractWithSigner = blitzzzContract.connect(signer)

    blitmapContract = new ethers.Contract(
      process.env.VUE_APP_BLITMAP_ADDRESS,
      blitmapABI,
      ethersInstance,
    )

    dreamloopContract = new ethers.Contract(
      process.env.VUE_APP_DREAMLOOP_ADDRESS,
      blitmapABI,
      ethersInstance,
    )

    dispatch('updateTotalSupply')

    let blitmapBalance = await blitmapContract.balanceOf(state.connectionInfo.address)
    commit('setBlitmapBalance', blitmapBalance.toNumber())

    if (blitmapBalance.toNumber() > 0) {
      let blitmapList = await tokenList(
        process.env.VUE_APP_BLITMAP_ADDRESS,
        state.connectionInfo.address,
      )
      commit('setBlitmaps', blitmapList)
    }

    let dreamloopBalance = await dreamloopContract.balanceOf(state.connectionInfo.address)
    commit('setDreamloopBalance', dreamloopBalance.toNumber())

    if (dreamloopBalance.toNumber() > 0) {
      let dreamloopList = await tokenList(
        process.env.VUE_APP_DREAMLOOP_ADDRESS,
        state.connectionInfo.address,
      )

      let loops = []
      for (const dreamloop of dreamloopList) {
        const response = await fetch(
          `https://dlv1api.bitlectrolabs.com/dreamloopsv1/metadata/${dreamloop}`,
        )
        const data = await response.json()
        loops.push({
          id: dreamloop,
          image: data.image,
          audio: data.animation_url,
        })
      }
      commit('setDreamloops', loops)
    }

    ///// ////////
    /// CONTRACT EVENTS
    //////////////

    let incomingFilter = blitzzzContract.filters.Transfer(null, state.connectionInfo.address)

    // Listen for incoming blitz NFTs
    blitzzzContract.on(incomingFilter, (from, to) => {
      if (
        ethers.utils.getAddress(from) ==
        ethers.utils.getAddress('0x0000000000000000000000000000000000000000')
      ) {
        commit('setIsMinting', false)
        commit('setHasMinted', true)
        toast.success('Minting was successful', {
          timeout: 7500,
        })
      }
    })

    // // Listen for outgoing VITA transactions (e.g. staking)
    // tokenContract.on(outgoingFilter, (from, to, amount) => {
    //   dispatch('updateUserBalances')
    //   console.log(`I sent ${amount} to ${to}.`)
    //   if (
    //     ethers.utils.getAddress(to) ==
    //     ethers.utils.getAddress(process.env.VUE_APP_STAKING_CONTRACT_ADDRESS)
    //   ) {
    //     commit('setStakingVita', false)
    //     toast.success('Staking was successful', {
    //       timeout: 7500,
    //     })
    //   }
    // })

    // // Listen for VITA token approval
    // tokenContract.on(
    //   tokenContract.filters.Approval(
    //     state.connectionInfo.address,
    //     process.env.VUE_APP_STAKING_CONTRACT_ADDRESS,
    //   ),
    //   (owner, spender, amount) => {
    //     if (amount.gte(ethers.utils.parseUnits(VITA_MAX_APPROVE_AMOUNT, VITA_TOKEN_DECIMALS))) {
    //       // Approval event seems to fire even when vita is staked/unstaked,
    //       // so we have to check if we were actually approving the VITA token
    //       if (state.approvingVita == true) {
    //         toast.success('VITA Token was enabled', {
    //           timeout: 7500,
    //         })
    //       }

    //       commit('setVitaIsApproved', true)
    //       commit('setApprovingVita', false)
    //     }
    //   },
    // )

    // // Listen for stake balance changes
    // stakingContract.on(stakingContract.filters.StakeChanged(null), (address) => {
    //   dispatch('updateUserBalances')
    //   if (
    //     ethers.utils.getAddress(address) == ethers.utils.getAddress(state.connectionInfo.address)
    //   ) {
    //     commit('setStakingVita', false)
    //     commit('setUnstakingVita', false)
    //   }
    // })

    // // Listen for DAO votes
    // daoContract.on('Voted', (voter, proposalId, weight, direction) => {
    //   console.log(
    //     `${voter} voted on ${proposalId} with ${ethers.utils.formatUnits(
    //       weight,
    //       VITA_TOKEN_DECIMALS,
    //     )} VITA with ${direction}`,
    //   )
    //   if (ethers.utils.getAddress(voter) == ethers.utils.getAddress(state.connectionInfo.address)) {
    //     commit('setLastVotedProposalId', parseInt(proposalId))
    //     commit('setIsVoting', false)
    //     toast.success('Your vote was successful', {
    //       timeout: 7500,
    //     })
    //   }
    // })

    ///// ////////
    /// GLOBAL CHAIN EVENTS
    //////////////

    // Listen for new ethereum blocks
    ethersInstance.on('block', (blockNumber) => {
      // console.log('New block:', blockNumber)
      dispatch('updateTotalSupply')
      commit('setCurrentBlock', blockNumber.toString())
    })

    // Reload page when chain was changed
    ethersInstance.provider.on('chainChanged', (chainId) => {
      console.log(`chain changed to ${chainId}`)
      window.location.reload()
      // let newChain = ethers.BigNumber.from(chainId).toNumber()
      // if (parseInt(newChain) != parseInt(process.env.VUE_APP_ETHEREUM_CHAIN_ID)) {
      //   window.location.reload()
      // } else {
      //   window.location.reload()
      // }
    })

    // Subscribe to session disconnection
    ethersInstance.on('disconnect', (code, reason) => {
      console.log('provider disconnect:', code, reason)
    })

    // Reload page if connected account was changed
    ethersInstance.provider.on('accountsChanged', function (accounts) {
      console.log('accountsChanged:', accounts[0])
      window.location.reload()
    })
  },
  async updateTotalSupply({ commit }) {
    let totalSupply = await blitzzzContract.totalSupply()
    commit('setTotalSupply', totalSupply.toNumber())
  },
  async checkOwnership({ state }, data) {
    try {
      let blitmapOwner = await blitmapContract.ownerOf(data.blitmapId)
      let dreamloopOwner = await dreamloopContract.ownerOf(data.dreamloopId)
      return (
        addressEqual(state.connectionInfo.address, blitmapOwner) &&
        addressEqual(state.connectionInfo.address, dreamloopOwner)
      )
    } catch {
      return false
    }
  },
  async checkAlreadyUsed({}, data) {
    // return false // <- to work on rinkeby
    let blitmapUsed = await blitzzzContract.blitmapUsed(data.blitmapId)
    let dreamloopUsed = await blitzzzContract.dreamloopUsed(data.dreamloopId)
    return blitmapUsed || dreamloopUsed
  },
  async forge({ state, commit }, data) {
    let overrides = {
      // To convert Ether to Wei:
      value:
        state.totalSupply >= 15
          ? ethers.utils.parseEther(process.env.VUE_APP_PRICE)
          : ethers.utils.parseEther('0.0'),
    }
    await blitzzzContractWithSigner.forge(data.blitmapId, data.dreamloopId, overrides)
    commit('setIsMinting', true)
    commit('setHasMinted', false)
    toast.info('Minting Transaction was broadcasted. Please wait for it to confirm.', {
      timeout: 7500,
    })
  },
  /////
  /////
  /////
  async updateUserBalances({ commit, state }) {
    const ethBalance = await ethersInstance.getBalance(state.connectionInfo.address)
    commit('setEthBalance', ethers.utils.formatEther(ethBalance))

    try {
      const vitaBalance = await tokenContract.balanceOf(state.connectionInfo.address)
      commit('setVitaBalance', ethers.utils.formatUnits(vitaBalance, VITA_TOKEN_DECIMALS))
    } catch {
      commit('setVitaBalance', 0)
    }

    try {
      const stakedBalance = await stakingContract.getStakedBalance(state.connectionInfo.address)
      commit('setStakedVitaBalance', ethers.utils.formatUnits(stakedBalance, VITA_TOKEN_DECIMALS))
    } catch {
      commit('setStakedVitaBalance', 0)
    }

    try {
      const unlockTime = await stakingContract.getUnlockTime(state.connectionInfo.address)
      commit('setStakeUnlockBlock', unlockTime.toString())
    } catch (e) {
      // Do nothing
    }
  },
  async connectInjected({ commit, dispatch }) {
    let accounts
    try {
      accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      commit('setProvider', 'injected')
      commit('setAddress', accounts[0])
      ethersInstance = new ethers.providers.Web3Provider(window.ethereum)

      signer = ethersInstance.getSigner()

      commit('setLoadingUserDetails', true)
      await dispatch('initUser')

      commit('connect')
      commit('setLoadingUserDetails', false)
    } catch (e) {
      console.log('user rejected or other error', e)
    }
  },
  async connectWalletConnect({ commit, dispatch }) {
    //  Create WalletConnect Provider
    const provider = new WalletConnectProvider({
      infuraId: process.env.VUE_APP_INFURA_API_KEY,
    })

    //  Enable session (triggers QR Code modal)
    await provider.enable()

    ethersInstance = new ethers.providers.Web3Provider(provider)
    signer = ethersInstance.getSigner()
    let accounts = await ethersInstance.listAccounts()
    commit('setProvider', 'walletconnect')
    commit('setAddress', accounts[0])

    await dispatch('initUser')

    commit('connect')
  },
  async disconnect({ commit }) {
    // Remove localstorage data from external WalletConnect library
    localStorage.removeItem(`walletconnect`)
    commit('disconnect')
    commit('removeProvider')
    commit('removeChainInfo')
    window.location.reload()
  },
}

// mutations
const mutations = {
  connect(state) {
    localStorageUtils.write('walletIsConnected', true)
    state.isConnected = true
  },
  disconnect(state) {
    localStorageUtils.remove('walletIsConnected')
    state.isConnected = false
  },
  setProvider(state, value) {
    localStorageUtils.write('walletProvider', value)
    state.walletProvider = value
  },
  removeProvider(state) {
    localStorageUtils.remove('walletProvider')
    state.walletProvider = null
  },
  setAddress(state, value) {
    state.connectionInfo.address = value
    localStorageUtils.write('connectionInfo', state.connectionInfo)
  },
  setChainId(state, value) {
    state.connectionInfo.chainId = value
    localStorageUtils.write('connectionInfo', state.connectionInfo)
  },
  removeChainInfo(state) {
    state.connectionInfo = {
      address: null,
      chainId: null,
    }
    localStorageUtils.remove('connectionInfo')
  },
  setEthBalance(state, value) {
    state.ethBalance = value
  },
  setEnsName(state, value) {
    state.ensName = value
  },
  setCurrentBlock(state, block) {
    state.currentBlock = block
  },
  setConnectWalletModalIsOpen(state, value) {
    state.connectWalletModalIsOpen = value
  },
  setLoadingUserDetails(state, value) {
    state.loadingUserDetails = value
  },
  setBlitmapBalance(state, value) {
    state.blitmapBalance = value
  },
  setBlitmaps(state, value) {
    state.blitmaps = value
  },
  setDreamloopBalance(state, value) {
    state.dreamloopBalance = value
  },
  setDreamloops(state, value) {
    state.dreamloops = value
  },
  setTotalSupply(state, value) {
    state.totalSupply = value
  },
  setIsMinting(state, value) {
    state.isMinting = value
  },
  setHasMinted(state, value) {
    state.hasMinted = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
